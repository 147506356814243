import React from "react"
import { Title, Paragraph, styled } from "@abtasty/pulsar-common-ui/"
import { useTranslation } from "gatsby-plugin-react-i18next"

import backgroundImage from "../../../static/header/img-header.png"

const Header = () => {
  const { t } = useTranslation()
  return (
    <HeaderWrapper>
      <HeaderContainer>
        <HeaderTitle level="1">
          {t("Integration Hub", { context: "header" })}
        </HeaderTitle>
        <Description type="description">
          {t(
            "An integration is a connection between AB Tasty and a third-party tool (e.g. analytics tools, CDPs…). When the connection is established, tools can exchange data, allowing the realization of use cases involving both tools.",
            { context: "header" }
          )}
        </Description>
      </HeaderContainer>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div`
  max-width: 1200px;
  margin: auto;
`

const HeaderContainer = styled.div`
  background-image: url(${backgroundImage});
  background-position: right center;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 350px;

  padding-top: 32px;
  padding-bottom: 16px;

  color: ${({ theme }) => theme.dec.color.primary.default.fg.norm};
`

const HeaderTitle = styled(Title)`
  margin: 0;
  font-style: normal;
  font-weight: 800;
  font-size: 41px;
  line-height: 49px;
  color: ${({ theme }) => theme.dec.color.primary.default.fg.brand};
`

const Description = styled(Paragraph)`
  max-width: 70ch;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
`

export default Header
