import React, { useState, useEffect, useMemo } from "react"
import { Col, Container, Row, styled } from "@abtasty/pulsar-common-ui"
import { useTranslation } from "gatsby-plugin-react-i18next"

import IntroCards from "../intro-cards/IntroCards"
import Card from "../card/Card"
import CustomCard from "../custom-card/CustomCard"

import { ID_CUSTOM_CARD, DEFAULT_NUMBER_CARDS } from "src/helpers/constant"
import { reOrderCardList } from "../../../../helpers/utils"

const PushCards = ({ cards }) => {
  const { t } = useTranslation()

  const type = "Push"

  const [orderedListCard, setOrderedListCard] = useState([])
  const [fullPushCards, setFullPushCards] = useState(cards)
  const [displayAllPush, setDisplayAllPush] = useState(false)

  useMemo(() => {
    const finalOrderCardList = reOrderCardList(cards)
    setOrderedListCard(finalOrderCardList)
    setFullPushCards(finalOrderCardList.slice(0, DEFAULT_NUMBER_CARDS))
  }, [cards])

  const handleDisplayAllPush = displayAll => {
    setDisplayAllPush(displayAll)
    if (displayAll) {
      setFullPushCards(orderedListCard)
    } else {
      setFullPushCards(orderedListCard.slice(0, DEFAULT_NUMBER_CARDS))
    }
  }

  return (
    <div>
      <IntroCards
        cards={cards}
        displayAll={displayAllPush}
        handleDisplayAll={handleDisplayAllPush}
        type={type}
        description={t(
          "Enable AB Tasty to send campaign data to your third-party tools.",
          { context: "push-cards" }
        )}
      />
      <SContainer as="div">
        <Row justifyContent="justifyContentFlexStart">
          {fullPushCards.map(card => (
            <Col key={card?.frontmatter?.id} xs={12} sm={4} md={4} lg={3}>
              {card?.frontmatter?.id !== ID_CUSTOM_CARD ? (
                <Card card={card} />
              ) : (
                <CustomCard card={card} type="Push" />
              )}
            </Col>
          ))}
        </Row>
      </SContainer>
    </div>
  )
}

const SContainer = styled(Container)`
  padding: 0 !important;
`
export default PushCards
