import { DEFAULT_NUMBER_CARDS, ID_CUSTOM_CARD } from "./constant"

export const reOrderCardList = cards => {
  const finalCardList = cards.filter(c => c?.frontmatter?.id !== ID_CUSTOM_CARD)
  const customCard = cards.find(c => c?.frontmatter?.id === ID_CUSTOM_CARD)
  if (customCard) {
    finalCardList.splice(DEFAULT_NUMBER_CARDS - 1, 0, customCard)
  }
  return finalCardList
}
