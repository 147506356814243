import React, { Suspense, lazy, useState } from 'react';

import { Button, Modal, Spinner, Title, styled } from '@abtasty/pulsar-common-ui';
import ModalIntegration from '../modal-integration/ModalIntegration';
import { useTranslation } from 'react-i18next';
import { Tag } from '../../../tag';

const Card = ({ card }) => {
  const { t } = useTranslation();
  const [displayModal, setDisplayModal] = useState(false);

  const openModal = () => setDisplayModal(true);
  const closeModal = () => setDisplayModal(false);

  const Content = lazy(() => import(`../../../../integrations/${card.frontmatter.filename}`));

  return (
    <WrapperCard onClickCapture={openModal}>
      <HeaderCard className={classNames.headerCard}>
        <HeaderLogo height="60px" src={`/logos/${card?.frontmatter.image}`} alt="logo-card" />
        <LeftBottomPosition>
          <Tag>{card?.frontmatter?.tag}</Tag>
        </LeftBottomPosition>
      </HeaderCard>

      <ContentCard>
        <ContentTitle level="5">{card?.frontmatter?.title}</ContentTitle>

        <Suspense fallback={<Spinner />}>
          <ContentDescription className={classNames.contentDescription}>
            {card?.frontmatter?.description}
            <Content />
          </ContentDescription>
        </Suspense>

        <MoreInfoButton className={classNames.moreInfoBtn} onClick={openModal} size="medium">
          {t('More info', { context: 'ContentCard' })}
        </MoreInfoButton>
      </ContentCard>

      <SModal contentLabel="integration" isOpen={displayModal} onRequestClose={closeModal}>
        <ModalIntegration closeModal={closeModal} card={card} />
      </SModal>
    </WrapperCard>
  );
};

const classNames = {
  headerCard: 'header-card',
  contentDescription: 'content-description',
  moreInfoBtn: 'more-info-btn',
};

const WrapperCard = styled.div`
  height: 173px;
  border-radius: 4px;
  min-height: 174px;
  border: 1px solid;
  border-color: transparent;

  &:hover {
    border-color: ${({ theme }) => theme.dec.color.brand.hovered.bg.norm} !important;

    .${classNames.headerCard} {
      background-color: ${({ theme }) => theme.dec.color.primary.default.sk.norm} !important;
    }

    .${classNames.contentDescription} {
      display: none !important;
      background: red !important;
    }

    .${classNames.moreInfoBtn} {
      display: block !important;
    }
  }
`;

const HeaderLogo = styled.img``;
const HeaderCard = styled.div`
  height: 80px;
  background-color: ${({ theme }) => theme.dec.color.primary.default.sk.minimal};
  border-radius: 3px 3px 0px 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${HeaderLogo} {
    width: auto;
    max-height: 60px;
  }
`;

const ContentCard = styled.div`
  position: relative;

  padding: 16px;
  background: white;
  height: 92px;
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const ContentDescription = styled.div`
  display: block;
  max-height: 30px;
  overflow: hidden;
  margin: 9px 0;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: ${({ theme }) => theme.dec.color.primary.default.fg.subbtle} !important;
`;

const ContentTitle = styled(Title)`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin: 0 9px 9px 0;
`;

const MoreInfoButton = styled(Button)`
  display: none !important;
  position: absolute;
  bottom: 16px;
  left: 16px;
  max-height: 24px !important;
  padding: 5px 12px !important;
  min-width: auto !important;
`;

export const SModal = styled(Modal)`
  width: 820px !important;
  max-width: none !important;
  position: relative !important;
  padding: 0 !important;
  height: auto !important;

  @media only screen and (max-width: 1284px) {
    width: 100vw !important;
  }
`;

const LeftBottomPosition = styled.div`
  position: absolute;
  left: 8px;
  bottom: 8px;
`;
export default Card;
