import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import { Title, Paragraph, Icon, styled } from "@abtasty/pulsar-common-ui"

const IntroCards = ({
  cards,
  type,
  description,
  displayAll,
  handleDisplayAll,
}) => {
  const { t } = useTranslation()

  const textDisplay = displayAll
    ? t("See less", { context: "integration-hub" })
    : t("See all", { context: "integration-hub" })

  const handleDisplay = () => {
    handleDisplayAll(!displayAll)
  }

  return (
    <Wrapper>
      <div>
        <IntroCardsTitle dataTestId="title-category" level="3">
          {type} ({cards.length})
        </IntroCardsTitle>
        <IntroCardsDescription type="description">
          {description}
        </IntroCardsDescription>
      </div>
      {cards.length ? (
        <DisplayAllButton onClick={handleDisplay}>
          <DisplayCardDescription type="description">
            {textDisplay}
          </DisplayCardDescription>
          <Icon
            icon={displayAll ? "chevronCircleUp" : "chevronCircleDown"}
            size="medium"
          />
        </DisplayAllButton>
      ) : null}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 35px 0 25px 0;
`

const IntroCardsTitle = styled(Title)`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.colorInk};
`

const IntroCardsDescription = styled(Paragraph)`
  margin-top: 16px;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.colorInk};
`

const DisplayCardDescription = styled(Paragraph)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.colorInk};
`

const DisplayAllButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`

export default IntroCards
