import React from 'react';
import { ABTastyLogo, ABTastyLogoFormats, Icon, Title, styled } from '@abtasty/pulsar-common-ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Tag } from '../../../../tag';

const ModalHeader = ({ card, closeModal }) => {
  const { t } = useTranslation();
  return (
    <ModalHeaderContainer as="div">
      <div>
        <TitleBlock>
          <DisplayNameTitle level="1">{card?.frontmatter?.title}</DisplayNameTitle>
          {card?.frontmatter?.tag ? <Tag>{card?.frontmatter?.tag}</Tag> : null}
        </TitleBlock>
        <LinkWrapper>
          <DocumentationLink href={card?.frontmatter?.documentation} target="_blank" rel="noreferrer">
            <span>👉</span>
            <span>
              {t('Read documentation', {
                context: 'MainContent',
              })}
            </span>
          </DocumentationLink>
        </LinkWrapper>
      </div>
      <RightColumn>
        <ABTastyLogo width="42px" format={ABTastyLogoFormats.AB_ROUND_OPENED_2024} />
        <Icon icon={card?.frontmatter?.type === 'provider' ? 'arrowAloneLeft' : 'arrowAloneRight'} size="large" />
        {card?.frontmatter.image && <ProviderLogo src={`/logos/${card?.frontmatter.image}`} alt="logo-card" />}
      </RightColumn>

      <CloseButton onClick={closeModal}>
        <Icon customSize={24} icon="closeCircle" />
      </CloseButton>
    </ModalHeaderContainer>
  );
};

const ModalHeaderContainer = styled.div`
  width: 100%;
  height: 120px;
  background: ${({ theme }) => theme.dec.color.primary.default.sh.brand};
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 32px 32px 0;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 18px;
  top: 18px;
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const DisplayNameTitle = styled(Title)`
  margin: 0 !important;
`;

const LinkWrapper = styled.div`
  margin: 16px 0 8px 0;
  display: flex;
`;
const DocumentationLink = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.dec.color.primary.default.fg.subbtle} !important;
  & span {
    margin: 0 5px;
  }
`;

const ProviderLogo = styled.img`
  width: 42px;
`;

const RightColumn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
  gap: 4px;
`;

export default ModalHeader;
