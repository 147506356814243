import React, { useState, useEffect, useMemo } from "react"
import { Col, Container, Row } from "@abtasty/pulsar-common-ui"

import Card from "../card/Card"
import IntroCards from "../intro-cards/IntroCards"

import { DEFAULT_NUMBER_CARDS } from "src/helpers/constant"

const PullCards = ({ cards }) => {
  const type = "Pull"
  const description =
    "Enable AB Tasty to retrieve segments from your third-party tools to be able to target them in your AB Tasty campaigns."

  const [isAllCardsDisplayed, setIsAllCardsDisplayed] = useState(false)
  const [displayedCards, setDisplayedCards] = useState(cards)

  useMemo(() => {
    setDisplayedCards(cards.slice(0, DEFAULT_NUMBER_CARDS))
  }, [cards])

  const handleDisplayAllPull = displayAll => {
    setIsAllCardsDisplayed(displayAll)
    if (displayAll) {
      setDisplayedCards(cards)
    } else {
      setDisplayedCards(cards.slice(0, DEFAULT_NUMBER_CARDS))
    }
  }

  return (
    <div>
      <IntroCards
        cards={cards}
        displayAll={isAllCardsDisplayed}
        handleDisplayAll={handleDisplayAllPull}
        type={type}
        description={description}
      />
      <div>
        <Row justifyContent="justifyContentFlexStart">
          {displayedCards.map(card => {
            return (
              <Col key={card?.frontmatter?.id} xs={12} sm={4} md={4} lg={3}>
                <Card card={card} />
              </Col>
            )
          })}
        </Row>
      </div>
    </div>
  )
}

export default PullCards
