import React, { lazy, Suspense } from "react"
import { graphql, useStaticQuery } from "gatsby"

import "./modalContent.css"
import { Spinner } from "@abtasty/pulsar-common-ui"

const ModalContent = ({ card }) => {
  const data = useStaticQuery(graphql`
    {
      allMdx(filter: { frontmatter: { pageType: { eq: "modal" } } }) {
        nodes {
          frontmatter {
            idModal
            filename
            type
          }
        }
      }
    }
  `)
  const allModalMdx = data.allMdx.nodes
  const item = allModalMdx.find(
    ({ frontmatter }) =>
      frontmatter.idModal === card.frontmatter.id &&
      frontmatter.type === card.frontmatter.type
  )
  const MyComponent = lazy(() =>
    import(`../../../../../integrations/${item.frontmatter.filename}`)
  )
  return <Suspense fallback={<Spinner />}>{item && <MyComponent />}</Suspense>
}

export default ModalContent
