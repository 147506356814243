import React, { useState } from "react"
import {
  ABTastyLogo,
  ABTastyLogoFormats,
  Icon,
} from "@abtasty/pulsar-common-ui"
import { useI18next, Link } from "gatsby-plugin-react-i18next"

// CSS
import "./banner.css"

const Banner = () => {
  const { language, languages, originalPath } = useI18next()
  let [isLangHover, setLangHover] = useState(false)

  return (
    <div className="flex-container">
      <div className="item img-logo">
        <ABTastyLogo
          height="50%"
          width="50%"
          format={ABTastyLogoFormats.ABTASTY}
        />
      </div>
      <div
        onMouseLeave={() => setLangHover(false)}
        className="item lang-picker"
      >
        <li>
          <a onMouseEnter={() => setLangHover(true)} className="top-level">
            <div className="menu-item-wrapper">
              <Icon
                icon="browser"
                color={isLangHover ? "abBlue" : "blue"}
                size="small"
              />
              <div
                className={`jet-menu-title ${
                  isLangHover ? `` : `is-active-hover`
                }`}
              >
                {language.toUpperCase()}
              </div>
            </div>
          </a>
          {isLangHover ? (
            <ul
              onMouseLeave={() => setLangHover(false)}
              className="lang-sub-menu"
            >
              {languages.map((lang, index) => {
                if (lang !== language) {
                  return (
                    <li key={index} id="jet-menu-item-52820-fr">
                      <Link to={originalPath} language={lang}>
                        {lang.toUpperCase()}
                      </Link>
                    </li>
                  )
                }
              })}
            </ul>
          ) : null}
        </li>
      </div>
    </div>
  )
}

export default Banner
