import React, { useState } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

// Components
import { Paragraph, Title, Input, styled } from "@abtasty/pulsar-common-ui"
import PushCards from "./cards/push-cards/PushCards"
import PullCards from "./cards/pull-cards/PullCards"

import Filter from "./filter/filter"
import { ID_CUSTOM_CARD } from "src/helpers/constant"

import "./mainContent.css"
import OtherCards from "./cards/other-cards/OtherCards"
import DatalayerCards from "./cards/datalayer-cards/DatalayerCards"

const MainContent = ({ providers, receivers, others, datalayers }) => {
  const [inputValue, setInputValue] = useState("")
  const [configsSelected, setConfigsSelected] = useState({})

  const { t } = useTranslation()

  const finalProviders = [...providers]
  const finalReceivers = [...receivers]
  const finalOthers = [...others]
  const finalDataLayers = [...datalayers]

  const filterCards = supplier => {
    const customCard = supplier.find(e => e?.frontmatter?.id === ID_CUSTOM_CARD)
    let result = supplier.filter(e => e?.frontmatter?.id !== ID_CUSTOM_CARD)
    if (inputValue) {
      const regex = new RegExp(inputValue, "i")
      result = result.filter(elem => {
        const stringFinder = elem.frontmatter.title.search(regex)
        if (stringFinder === -1) {
          return false
        }
        return true
      })
    }

    if (configsSelected?.suppliers?.length) {
      result = result.filter(elem => {
        return configsSelected.suppliers.includes(elem.frontmatter.type)
      })
    }

    if (configsSelected?.categories?.length) {
      result = result.filter(elem => {
        return configsSelected.categories.includes(elem.frontmatter.tag)
      })
    }
    if (customCard) {
      return [...result, customCard]
    }
    return [...result]
  }

  const filteredProviders = filterCards(finalProviders)
  const filteredReceivers = filterCards(finalReceivers)
  const filteredOthers = filterCards(finalOthers)
  const filteredDataLayers = filterCards(finalDataLayers)

  return (
    <Background>
      <Container>
        <FilterRow>
          <SearchInput
            type="search"
            className="search-bar"
            placeholder="Search..."
            value={inputValue}
            onChange={event => setInputValue(event.currentTarget.value)}
            clearSearch={() => setInputValue("")}
          />
          <Filter
            configsSelected={configsSelected}
            setConfigsSelected={setConfigsSelected}
          />
        </FilterRow>
        {filteredReceivers.length === 0 && filteredProviders.length === 0 ? (
          <div className="main-content-no-result-wrapper">
            <Title
              className="title-lato main-content-no-result-title"
              level="5"
            >
              {t("Oh no! No result matches your search...", {
                context: "MainContent",
              })}
            </Title>
            <Paragraph
              className="main-content-no-result-description"
              type="description"
            >
              {t("Here are the solutions we propose:", {
                context: "MainContent",
              })}
            </Paragraph>
          </div>
        ) : null}
        <PushCards cards={filteredReceivers} />
        <PullCards cards={filteredProviders} />
        <DatalayerCards cards={filteredDataLayers} />
        <OtherCards cards={filteredOthers} />
      </Container>
    </Background>
  )
}

const Background = styled.div`
  padding: 32px 0;
  background: ${({ theme }) => theme.dec.color.primary.default.bg.tone};
`

const Container = styled.div`
  max-width: 1200px;
  margin: auto;
`

const FilterRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;

  // first element must be search input
  > *:first-child {
    flex: 1;
  }
`
const SearchInput = styled(Input)`
  border: solid 1px ${({ theme }) => theme.colors.colorInkLight};
  border-radius: 4px;
`

export default MainContent
