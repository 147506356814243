import React, { useState } from 'react';
import { Button, Modal, Paragraph, Title, styled } from '@abtasty/pulsar-common-ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import ModalIntegration from '../modal-integration/ModalIntegration';

import { SModal } from '../card/Card';

const CustomCard = ({ type, card }) => {
  const [displayModal, setDisplayModal] = useState(false);
  const { t } = useTranslation();

  const openModal = () => setDisplayModal(true);
  const closeModal = () => setDisplayModal(false);

  return (
    <CustomCardWrapper onClickCapture={openModal}>
      <STitle level="5">
        {t(`Want to create a {{ type }} integration? `, {
          context: 'CustomCard',
          type: type,
        })}
      </STitle>
      <DescriptionWrapper>
        <IllustrationLogo
          className={classNames.illustrationLogo}
          src={type === 'Push' ? '/custom-card/logo-custom-push.png' : '/custom-card/logo-custom-pull.png'}
          alt="logo-card"
        />
        <Description>
          {t(
            `If the tool you want is not available, you can create your own {{ type }} integration by injecting Javascript code.`,
            {
              context: 'CustomCard',
              type: type,
            }
          )}
        </Description>
      </DescriptionWrapper>
      <CreateCustomIntegrationButton
        onClick={openModal}
        className={classNames.createCustomIntegrationButton}
        label={t(`Create {{ type }} integration`, {
          context: 'btn-custom',
          type: type,
        })}
        size="medium"
      />
      <SModal
        contentLabel={t('integration', {
          context: 'modalCustom',
        })}
        isOpen={displayModal}
        onRequestClose={closeModal}
      >
        <ModalIntegration card={card} closeModal={closeModal} />
      </SModal>
    </CustomCardWrapper>
  );
};

const classNames = {
  createCustomIntegrationButton: 'create-custom-integration-button',
  illustrationLogo: 'illustration-logo',
  descriptionText: 'description-text',
};

const CustomCardWrapper = styled.div`
  height: 173px;
  min-width: 250px;
  border: 1px solid;
  border-color: transparent;
  border-radius: 4px;
  padding: 0 16px;
  position: relative;
  background: white;
  color: ${({ theme }) => theme.dec.color.primary.default.fg.norm};

  &:hover {
    & {
      border-color: ${({ theme }) => theme.dec.color.brand.hovered.bg.norm} !important;
    }

    .${classNames.createCustomIntegrationButton} {
      display: block !important;
    }

    .${classNames.illustrationLogo} {
      display: none !important;
    }

    .${classNames.descriptionText} {
      margin: 20px 0 0 0;
    }
  }
`;

const STitle = styled(Title)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.dec.color.primary.default.fg.brand};
`;

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 120px;
`;

const Description = styled(Paragraph)`
  text-align: left;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: ${({ theme }) => theme.dec.color.primary.default.fg.norm} !important;
  margin: 0;
`;
const CreateCustomIntegrationButton = styled(Button)`
  display: none !important;
  position: absolute;
  left: 16px;
  bottom: 16px;
  max-height: 24px;
  min-width: auto;
  padding: 5px 12px;
`;

const IllustrationLogo = styled.img`
  width: 50px;
  height: 65px;
  margin-right: 20px;
  display: block;
`;
export default CustomCard;
