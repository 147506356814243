import React from "react"
import { graphql } from "gatsby"

import "@abtasty/pulsar-common-ui/static/reset.css"
// Include fonts
import "@abtasty/pulsar-common-ui/static/fonts.css"
// Include library CSS
import "@abtasty/pulsar-common-ui/dist/commonui.css"

// Components
import Header from "../components/header/header"
import Banner from "../components/banner/banner"
import MainContent from "../components/main-content/MainContent"

import { StyledProvider, themeV2 } from "@abtasty/pulsar-common-ui"
// import PushCards from "../components/main-content/cards/push-cards/PushCards"

export default function Home({ data }) {
  return (
    <StyledProvider theme={themeV2}>
      <Banner />
      <Header />
      <MainContent
        providers={data.providers.nodes}
        receivers={data.receivers.nodes}
        others={data.others.nodes}
        datalayers={data.datalayer.nodes}
      />
    </StyledProvider>
  )
}

export const query = graphql`
  query MyQuery($language: String!) {
    providers: allMdx(
      filter: {
        frontmatter: { type: { eq: "provider" }, pageType: { eq: "card" } }
      }
      sort: { fields: frontmatter___id, order: ASC }
    ) {
      nodes {
        frontmatter {
          id
          title
          documentation
          slug
          type
          tag
          image
          filename
        }
      }
    }
    receivers: allMdx(
      filter: {
        frontmatter: { type: { eq: "receiver" }, pageType: { eq: "card" } }
      }
      sort: { fields: frontmatter___id, order: ASC }
    ) {
      nodes {
        frontmatter {
          id
          title
          documentation
          slug
          type
          tag
          image
          filename
        }
      }
    }
    datalayer: allMdx(
      filter: {
        frontmatter: { type: { eq: "datalayer" }, pageType: { eq: "card" } }
      }
      sort: { fields: frontmatter___id, order: ASC }
    ) {
      nodes {
        frontmatter {
          id
          title
          documentation
          slug
          type
          tag
          image
          filename
        }
      }
    }
    others: allMdx(
      filter: {
        frontmatter: { type: { eq: "other" }, pageType: { eq: "card" } }
      }
      sort: { fields: frontmatter___id, order: ASC }
    ) {
      nodes {
        frontmatter {
          id
          title
          documentation
          slug
          type
          tag
          image
          filename
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

// export const query = graphql`
//   query MyQuery($language: String!) {
//     providers: allMarkdownRemark(
//       filter: { frontmatter: { type: { eq: "provider" } } }
//       sort: { fields: frontmatter___id, order: ASC }
//     ) {
//       nodes {
//         frontmatter {
//           id
//           title
//           slug
//           documentation
//           type
//           useCases
//           tag
//           image
//         }
//         html
//       }
//     }
//     receivers: allMarkdownRemark(
//       filter: { frontmatter: { type: { eq: "receiver" } } }
//       sort: { fields: frontmatter___id, order: ASC }
//     ) {
//       nodes {
//         frontmatter {
//           id
//           title
//           slug
//           documentation
//           type
//           useCases
//           tag
//           image
//         }
//         html
//       }
//     }
//     locales: allLocale(filter: { language: { eq: $language } }) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `
