import React from 'react';

import ModalHeader from './modal-header/ModalHeader';
import ModalContent from './modal-content/ModalContent';

const ModalIntegration = ({ card, closeModal }) => {
  return (
    <div>
      <ModalHeader closeModal={closeModal} card={card} />
      <ModalContent card={card} />
    </div>
  );
};

export default ModalIntegration;
