import { Tag as TagComponent, styled } from "@abtasty/pulsar-common-ui"

export const Tag = styled(TagComponent)`
  background: ${({ theme }) =>
    theme.dec.color.brand.default.bg.norm} !important;
  color: white !important;
  border-radius: 11px !important;
  font-weight: 700 !important;
  line-height: 14px !important;
  padding: 4px 8px !important;
  word-break: keep-all;
`
