import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import {
  FiltersPanel,
  Button,
  getAppliedFiltersCount,
  FilterGroupTypes,
  styled,
} from "@abtasty/pulsar-common-ui"

const Filter = ({
  configsSelected,
  setConfigsSelected,
  dispatchSupplierManager,
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const { t } = useTranslation()

  const groups = [
    {
      items: [
        { label: "Push", value: "receiver" },
        { label: "Pull", value: "provider" },
        { label: "Datalayer", value: "datalayer" },
        { label: "other", value: "other" },
      ],
      key: "suppliers",
      title: "Use case",
      type: FilterGroupTypes.CHECKBOX,
    },
    {
      items: [
        { label: "Analytics", value: "Analytics" },
        { label: "CDP", value: "CDP" },
        { label: "Replay", value: "Replay" },
        { label: "TMS", value: "TMS" },
        { label: "CMS", value: "CMS" },
        { label: "Workflow", value: "Workflow" },
        { label: "Call tracking", value: "Call tracking" },
      ],
      key: "categories",
      title: "Category",
      type: FilterGroupTypes.CHECKBOX,
    },
  ]

  const { count: filtersCount } = getAppliedFiltersCount(
    configsSelected,
    groups
  )

  const applyFilters = values => {
    setConfigsSelected(values)
    setIsOpened(false)
  }

  return (
    <>
      <FilterButton
        color="white"
        shadow
        onClick={e => {
          setIsOpened(true)
          e.stopPropagation()
        }}
      >
        <span>
          {t("Filters", { context: "integration-hub" })}
          {filtersCount !== 0 && <FilterCount> {filtersCount}</FilterCount>}
        </span>
      </FilterButton>

      {typeof window !== "undefined" && (
        <FiltersPanel
          topPosition={64}
          labels={{
            header: () => {
              return t("Filter by", { context: "hub-integration" })
            },
            apply: t("Apply", { context: "integration-hub" }),
            reset: t("Reset", { context: "integration-hub" }),
          }}
          opened={isOpened}
          close={() => setIsOpened(false)}
          applyFilters={applyFilters}
          values={configsSelected}
          resetValues={{}}
          config={{ groups }}
          isFixedOnScroll
        />
      )}
    </>
  )
}

export default Filter

const FilterButton = styled(Button)`
  height: unset !important;
  width: 87px;
`

const FilterCount = styled.span`
  background-color: ${({ theme }) => theme.dec.color.brand.default.bg.norm};
  border-radius: 50%;
  height: 20px;
  width: 20px;
  line-height: 21px;
  display: inline-block;
  text-align: center;
  color: #ffffff;
  margin-left: 5px;
  font-size: 11px;
  font-weight: 700;
  font-style: normal;
`
